<template>
  <!-- 产品轮廓 -->
  <div
    id="RoughSketch"
    v-loading="loading"
    :element-loading-text="
      downloadRole
        ? this.$t(`message.loading.downloading`)
        : loading
        ? this.$t(`message.loading.suanfa`)
        : downloadRole
        ? this.$t(`message.loading.downloadcompletes`)
        : this.$t(`message.loading.Successsuanfa`)
    "
    style="width: 100%">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <Header :info="info"></Header>
      <!-- 选择项目\选择产品 -->
      <div class="header_bar">
        <!-- 第一行 -->
        <div class="bar_One">
          <div class="Page_title">
            {{ $t(`message.Category.page1.Title`) }}
            <div class="line"></div>
          </div>

          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t(`message.Category.common.Category`)" class="bar_One_text_0">
              <el-select v-model="formInline0.product" :placeholder="$t(`message.Category.page4.SelectOne`)" @change="changeName">
                <el-option v-for="(item, index) in program_name" :key="index" :label="item" :value="index"></el-option>
              </el-select>
            </el-form-item>

            <div class="block">
              <span class="demonstration">{{ $t(`message.Category.common.Products`) }}</span>
              <el-cascader
                v-model="contrast"
                :placeholder="$t(`message.Category.common.Select`)"
                :value="values"
                :options="options"
                :show-all-levels="false"
                :props="{ multiple: true, emitPath: true }"
                filterable
                ref="cascaderAddr"
                popper-class="location"
                clearable
                @change="Cvalue"
                :label="label"
                ><template slot-scope="{ data }">
                  {{ data.label }}
                  <span v-if="contrast.length >= 10" class="exceeded-overlay" @click="handleSelectionExceeded"></span> </template
              ></el-cascader>
            </div>
          </el-form>
          <div class="button" v-show="$store.state.lang == 'cn'">
            <div class="rest">
              <img src="https://newstore.vynior.com/rest_choose.png" alt="" @click="clear_num" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/download.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>

            <div class="port">
              <img src="https://newstore.vynior.com/Group%2033282%402x.png" alt="" @click="ActionAnalyse" />
            </div>
          </div>
          <div class="button" v-show="$store.state.lang == 'en'">
            <div class="rest">
              <img src="https://newstore.vynior.com/rrrrssst.png" alt="" @click="clear_num" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/xzxz.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>

            <div class="port">
              <img src="https://newstore.vynior.com/fenxifx.png" alt="" @click="ActionAnalyse" />
            </div>
          </div>
        </div>
      </div>

      <!-- 下面的内容box -->
      <div class="Content_box">
        <!-- 左侧 -->
        <div class="Product_left">
          <!-- 图标描述 隐藏的状态 -->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/Group%2033821.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
          <!-- 图标描述 隐藏的状态 english -->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'en'">
            <img src="https://newstore.vynior.com/emptymiaos.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
          <!-- 图标描述 显示的状态 (无表格状态) -->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/updateDR.png" alt="" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>
          <!-- 图标描述 显示的状态 (无表格状态)english -->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'en'">
            <img src="https://newstore.vynior.com/yingweng.png" alt="" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>
        </div>

        <!-- 右侧 -->
        <div class="Product_right">
          <el-select v-if="show == 1" v-model="likevalue" placeholder="请选择">
            <!-- @change="likeChange"
                  v-show="change_bar_num == 1" -->
            <el-option v-for="item in likeoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <div class="Product_show-switch" v-show="show == 1">
            <div class="Product_text">{{ $t(`message.Category.page1.Range`) }}</div>
            <el-switch v-model="value" active-color="#1D5CA6"> </el-switch>
          </div>
          <!-- 空状态 -->
          <div class="none" v-show="show == 0">
            <img src="https://newstore.vynior.com/Group%203%402x.png" alt="" />
            <p>{{ $t(`message.Category.page1.empty`) }}</p>
          </div>

          <!-- 分析后的数据 -->
          <div class="Product_show" v-show="show == 1">
            <!-- !  正常图片 -->
            <img
              v-if="this.IMGlist.length > 2"
              :src="this.$store.state.lang == 'cn' ? this.IMGlists_cn[this.ChangeImgIndex] : this.IMGlists_en[this.ChangeImgIndex]"
              alt="" />
            <!-- !  异常图片图片 -->
            <img v-if="this.IMGlist.length == 2" :src="this.IMGlist[this.value == true ? 1 : 0]" alt="" />
            <div class="Product_show-tooltip" v-show="showData.length != 0">
              <div
                class="tooltip-one"
                v-for="(it, i) in min"
                :class="i > 0 ? 'tooltip-two' : 'tooltip-one'"
                :key="i"
                @mouseover="mouseOverChange(i)"
                @mouseleave="mouseLeaveChange">
                <el-tooltip
                  :open-delay="500"
                  content="123"
                  :placement="i >= 24 ? 'left' : 'right'"
                  :enterable="false"
                  :visible-arrow="false"
                  effect="light">
                  <div slot="content" class="content123">
                    <div class="content-name" v-show="$store.state.lang == 'cn'">{{ x_name[i] }}</div>
                    <div class="content-name" v-show="$store.state.lang == 'en'">{{ x_nameEN[i] }}</div>
                    <div class="content-box" v-for="(item, index) in showSortArr" :key="index">
                      <div class="content-bgcolor" :style="{ 'background-color': item.color }"></div>
                      <div class="content-id">
                        <span>{{ item.name }}</span>
                      </div>
                      <div class="content-label">
                        <span>{{ item.label }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <el-button :class="i>2&&i<26?'but':''" v-show="tooltipInfo==i"></el-button> -->
                  <el-button v-show="tooltipInfo == i" :style="{ 'margin-top': (legend_h / 16) * 0.88 + 'rem' }"></el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import { HttpClient } from '@/utils/http';
import getFilename from '../../utils/getFilename';
export default {
  name: 'RoughSketch',
  components: {
    Header,
  },
  data() {
    return {
      // ! 选择框
      likeoptions: [
        {
          //下拉框的数据
          value: '1',
          label: 'ALL SPF',
        },
        {
          value: '3',
          label: 'SPF≥50',
          disabled: true,
        },
        {
          value: '5',
          label: 'SPF<50',
        },
        {
          value: '9',
          label: '物理防晒',
        },
        {
          value: '7',
          label: '化学防晒',
        },

        {
          value: '11',
          label: '物化结合',
        },
        {
          value: '13',
          label: '喷雾类',
        },
      ],
      // ! 选项值
      likevalue: '',
      legend_h: null,
      contentInfo: null, //提示文字显示隐藏
      time: '', //定时器
      x_name: [
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '总体吸收速度',
      ],
      x_nameEN: [
        'Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability(10rubs)',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Speed of absorption',
      ],
      min: [
        '2.5',
        '3.6',
        '0.5',
        '1.6',
        '5.2',
        '2.0',
        '5.7',
        '0.6',
        '1.2',
        '2.2',
        '5.6',
        '4.6',
        '3.7',
        '5.9',
        '5.5',
        '4.4',
        '6.4',
        '5.4',
        '4.7',
        '5.5',
        '5.0',
        '6.4',
        '3.7',
        '3.2',
        '4.3',
        '1.4',
        '3.2',
        '0.8',
        '3.8',
      ],
      max: [
        '9.3',
        '7.3',
        '8.9',
        '10.4',
        '11.7',
        '10.2',
        '9.7',
        '9.6',
        '10.2',
        '11.0',
        '11.3',
        '8.3',
        '7.8',
        '8.9',
        '9.5',
        '9.0',
        '10.1',
        '8.6',
        '8.3',
        '9.3',
        '9.5',
        '10.7',
        '7.4',
        '7.1',
        '8.2',
        '10.3',
        '12.6',
        '9.0',
        '7.8',
      ],
      ShowDataAll: [],
      showData: [], //选择产品返回的数据
      showSortArr: [], //经过排序后的二维数组
      bgcolor: [
        '#0091FC',
        '#00CF91',
        '#00D0D0',
        '#17A60A',
        '#1CE018',
        '#2D1DDD',
        '#3E68FF',
        '#507197',
        '#8613E1',
        '#89C900',
        '#A128FF',
        '#A400BF',
        '#B8870B',
        '#D31E1E',
        '#D539EE',
        '#E706D0',
        '#E8D000',
        '#FC9A3F',
        '#FF249A',
        '#FF3A27',
      ],
      tooltipInfo: null, //鼠标移入的tooltip
      downloadLoading: false,
      downloadShow: false,
      info: 3, //fuzhuanzi
      values: '',
      formInline: { region: '', product: '' },
      formInlines: { region: '', product: '' },
      program_name: [], //存储项目名称
      product_name: [], //产品品类数组
      formInline0: [],
      value: true,
      options: [],
      contrast: '',
      label: '',
      name: '',
      idlist: [], //选取后的id数组
      labellist: [], //选中的label数组
      labelcolorobj: {}, //选中名字对应的线段颜色
      show: 0,
      IMGlist: [],
      DefaultIndex: 0,
      IMGlists_en: [], //当前展示的图片
      IMGlists_cn: [], //当前展示的图片
      indexing: 0,
      loading: false,
      taskID: '',
      downloadRole: false,
      showChart: 1, //控制 图表描述的显示隐藏
      ChangeImgIndex: 1, //控制图表显示的
    };
  },
  watch: {
    'formInline0.product': {
      handler(newv, oldv) {
        console.log(newv);
        if (newv != oldv) {
          if (newv != 0) {
            this.show = 0;
            return;
          }
          this.show = 1;
          this.likevalue = '1';
          // this.req_products();
          this.clear_num();
          // this.req_imgs();
        }
      },
    },
    // 监听 峰值显示
    value() {
      if (this.value == true) {
        this.ChangeImgIndex = this.likevalue;
      } else {
        this.ChangeImgIndex = JSON.parse(JSON.stringify(this.likevalue - 1));
      }
    },

    //限制选择数量
    // contrast: {
    //   handler(newv, oldv) {
    //     if (newv != "") {
    //       // this.not_img = 0;
    //       console.log("选择的数据是", newv);
    //       // if (newv.length > 10) {
    //       //   //消息提示
    //       //   this.$message({
    //       //     message: "最多只能选择10个对比产品哦！",
    //       //     type: 'warning'
    //       //   })
    //       //   this.$nextTick(() => {
    //       //     this.contrast = oldv
    //       // })
    //       // }
    //     }
    //   },
    // },
    // "contrast.length": {
    //   handler(newv, oldv) {
    //     if (newv != "") {
    //       // this.not_img = 0;
    //       console.log("选择的数据长度是", newv);
    //       if (newv > 10) {
    //         //消息提示
    //         this.$message({
    //           message: "最多只能选择10个对比产品哦！",
    //           type: "warning",
    //         });

    //         // let num = newv - 10;
    //         // this.delete_val(num);
    //       }
    //     }
    //   },
    // },
    '$store.state.lang': {
      handler() {
        if (this.$store.state.lang == 'cn') {
          //    if(this.IMGlist.length !== 5){
          //   this.IMGlist = ['https://newstore.vynior.com/line_cn.png', 'https://newstore.vynior.com/none_cn.png'];
          // }
          if (this.program_name[0] === 'Sun care') {
            this.$set(this.program_name, 0, '防晒霜');
          }
          this.likeoptions = [
            {
              //下拉框的数据
              value: '1',
              label: 'ALL SPF',
            },
            {
              value: '3',
              label: 'SPF≥50',
              disabled: true,
            },
            {
              value: '5',
              label: 'SPF<50',
            },
            {
              value: '9',
              label: '物理防晒',
            },
            {
              value: '7',
              label: '化学防晒',
            },
            {
              value: '11',
              label: '物化结合',
            },
            {
              value: '13',
              label: '喷雾类',
            },
          ];

          switch (this.likevalue) {
            case 'Chemical':
              this.likevalue = '物理防晒';
              break;
            case 'Physical':
              this.likevalue = '化学防晒';
              break;
            case 'Hybird':
              this.likevalue = '物化结合';
              break;
          }
        } else if (this.$store.state.lang == 'en') {
          //  if(this.IMGlist.length !== 5){
          //         this.IMGlist = ['https://newstore.vynior.com/line_en.png', 'https://newstore.vynior.com/none_en.png'];
          //       }
          if (this.program_name[0] === '防晒霜') {
            this.$set(this.program_name, 0, 'Sun care');
          }
          this.likeoptions = [
            {
              //下拉框的数据
              value: '1',
              label: 'ALL SPF',
            },
            {
              value: '3',
              label: 'SPF≥50',
              disabled: true,
            },
            {
              value: '5',
              label: 'SPF<50',
            },
            {
              value: '9',
              label: 'Chemical',
            },
            {
              value: '7',
              label: 'Physical',
            },
            {
              value: '11',
              label: 'Hybird',
            },
            {
              value: '13',
              label: 'spray',
            },
          ];
          // !切换判断赋值
          switch (this.likevalue) {
            case '物理防晒':
              this.likevalue = 'Chemical';
              break;
            case '化学防晒':
              this.likevalue = 'Physical';
              break;
            case '物化结合':
              this.likevalue = 'Hybird';
              break;
          }
        }
      },
    },
    likevalue: {
      handler() {
        console.log('this.likevalue :>> ', this.likevalue);
        if (this.value == false) {
          this.ChangeImgIndex = this.likevalue - 1;
          console.log('this.ChangeImgIndex :>> ', this.ChangeImgIndex);
        } else {
          this.ChangeImgIndex = this.likevalue;
          console.log('this.ChangeImgIndex :>> ', this.ChangeImgIndex);
        }

        // let img = '';
        // switch (this.likevalue) {
        //   case '5':
        //     console.log('等于5');
        //     img = this.IMGlist.slice(0, 4);
        //     this.IMGlists_en = img.slice(0, 2);
        //     this.IMGlists_cn = img.slice(2, 4);
        //     break;
        //   case '4':
        //     console.log('等于4');
        //     img = this.IMGlist.slice(4, 8);
        //     this.IMGlists_en = img.slice(0, 2);
        //     this.IMGlists_cn = img.slice(2, 4);
        //     break;
        //   case '3':
        //     console.log('等于3');
        //     img = this.IMGlist.slice(8, 12);
        //     this.IMGlists_en = img.slice(0, 2);
        //     this.IMGlists_cn = img.slice(2, 4);
        //     break;
        //   case '2':
        //     console.log('等于2');
        //     img = this.IMGlist.slice(12, 16);
        //     this.IMGlists_en = img.slice(0, 2);
        //     this.IMGlists_cn = img.slice(2, 4);
        //     break;
        //   case '1':
        //     console.log('等于1');
        //     img = this.IMGlist.slice(16, 20);
        //     this.IMGlists_en = img.slice(0, 2);
        //     this.IMGlists_cn = img.slice(2, 4);
        //     break;
        //   case '0':
        //     console.log('等于0');
        //     this.IMGlist;
        //     this.IMGlists_en = [];
        //     this.IMGlists_cn = [];
        //     this.IMGlists_en.push(this.IMGlist[20], this.IMGlist[22]);
        //     this.IMGlists_cn.push(this.IMGlist[23], this.IMGlist[25]);
        //     break;
        // }
      },
    },
    showDate: {
      handler(newValue) {
        console.log('进入:>> ');
        this.x_name = newValue.x_name;
        this.min = newValue.MIN;
        this.max = newValue.MAX;
      },
    },
  },
  methods: {
    // 鼠标移入图表
    mouseOverChange(val) {
      // console.log(this.showData);
      if (this.showData == [] || this.tooltipInfo == val) {
        return;
      }
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.tooltipInfo = val;
      }, 100);
      this.contentInfo = val;

      // console.log(this.showDazta);
      let arr = [];
      this.labellist.forEach((item, index) => {
        arr[index] = {};
        arr[index].name = item;
        arr[index].color = this.labelcolorobj[item];
        arr[index].label = this.showData[item][val];
      });
      // console.log(arr);
      function down(x, y) {
        return y.label - x.label;
      }
      //data是上面数据名，需要先引入，然后sort函数中放up函数，就可以实现对接送进行排序
      arr.sort(down);
      // console.log(arr, 'arr');
      this.showSortArr = arr;
      // console.log(this.tooltipInfo);
    },
    // 鼠标移出图表
    mouseLeaveChange() {
      clearTimeout(this.time);
      this.tooltipInfo = null;
      this.contentInfo = null;
    },
    // NOTE下载图表
    async downloadIMG() {
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      this.downloadRole = true;
      this.downloadLoading = true;
      let location = this.indexing;
      let response = await HttpClient.post(
        'CatAna_task/outline_download',
        {
          // type: "outline",
          taskid: this.taskID,
          type: parseInt(this.likevalue),
          location,
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let outlinehref = window.URL.createObjectURL(blob);
      downloadElement.href = outlinehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(outlinehref);
      this.downloadLoading = false;
      this.downloadRole = false;
    },
    async downloadIMGALL() {
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      this.downloadRole = true;
      this.loading = true;
      // let location = this.indexing;
      let response = await HttpClient.post(
        'CatAna_task/outline_downloadAll',
        {
          // type: "outline",
          taskid: this.taskID,
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let outlinehref = window.URL.createObjectURL(blob);
      downloadElement.href = outlinehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(outlinehref);
      this.loading = false;
      this.downloadRole = false;
    },
    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);
      this.program_name = res.classification;
      if (this.$store.state.lang == 'cn') {
        console.log('this.program_name CN:>> ', this.program_name);
        this.program_name.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case 'Sun care':
              this.$set(this.program_name, index, '防晒霜');
              break;
            case 'Toner':
              this.$set(this.program_name, index, ' 爽肤水');
              break;
            case ' Lotion':
              this.$set(this.program_name, index, '乳液');
              break;
            case 'Hand lotion':
              this.$set(this.program_name, index, '护手霜');
              break;
            default:
              break;
          }
        });
      } else {
        console.log('this.program_name EN :>> ', this.program_name);
        this.program_name.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case '防晒霜':
              // item = 'Sun care';
              this.$set(this.program_name, index, 'Sun care');
              break;
            case '爽肤水':
              item = 'Toner';
              this.$set(this.program_name, index, 'Toner');
              break;
            case '乳液':
              item = 'Lotion';
              this.$set(this.program_name, index, 'Lotion');
              break;
            case '护手霜':
              item = 'Hand lotion';
              this.$set(this.program_name, index, 'Hand lotion');
              break;
            default:
              break;
          }
        });
      }
      console.log(this.program_name);
    },
    //获取 对比产品数据
    async req_products() {
      HttpClient.post('program/history_list', {
        user_id: this.$store.state.userid,
        category: this.name,
      }).then((res) => {
        console.log(res);
        let newlist = res.result;
        console.log(newlist);
        let labellist = [];
        newlist.forEach((item) => {
          labellist.push(item.label);
        });
        console.log(labellist);
        console.log(newlist);
        console.log(this.options);
        this.options = newlist;
        console.log(this.options);
      });
    },

    // 选择品类后展示图片
    // async req_category_img() {
    //   let res = await HttpClient.post("CatAna_task/before_outline", {
    //     category: this.name,
    //   });
    //   console.log(res.result);
    //   this.IMGlist[0] = res.result;
    //   console.log(this.IMGlist);
    //   this.show = 1;
    //   // this.program_name = res.classification;
    //   // console.log(this.program_name);
    // },

    // 获取分析结果
    async Analyse() {
      this.IMGlists_en = [];
      this.IMGlists_cn = [];
      let res = await HttpClient.post('CatAna_task/outline', {
        _id: this.$store.state.userid,
        category: this.name,
        productlist: this.idlist,
      });

      console.log(res);
      this.IMGlist = res.result.outline.slice().reverse();
      res.result.outline.forEach((item) => {
        if (item.includes('en')) {
          this.IMGlists_en.push(item);
        } else {
          this.IMGlists_cn.push(item);
        }
      });
      if (res.result.outline.length != 0) {
        this.loading = false;
      }
      this.IMGlists_en.splice(1, 1);
      this.IMGlists_cn.splice(1, 1);

      this.ShowDataAll = res.result.showData;
      // this.showData = this.ShowDataAll.data_not_spray_cn;
      this.showData = this.$store.state.lang == 'cn' ? this.ShowDataAll.data_not_spray_cn : this.ShowDataAll.data_not_spray_en;

      console.log('this.IMGlists_en,this.IMGlists_cn :>> ', this.IMGlists_en, this.IMGlists_cn);
      console.log('this. ShowDataAll:>> ', this.ShowDataAll);
      // this.showData = this.$store.state.lang == 'cn' ? res.result.showData.data_cn : res.result.showData.data_en;
      // this.legend_h = Number(res.result.showData.legend_h);
      // // console.log( this.legend_h);
      // console.log(this.showData[0]);
      this.taskID = res.result._id;
      // console.log(this.program_name);
    },

    // 类目值改变
    changeName(e) {
      console.log(e);
      this.idlist = [];
      console.log(this.program_name[e]);
      if (this.program_name[e] == 'Sun care') {
        this.name = '防晒霜';
      } else {
        this.name = this.program_name[e];
      }

      // 获取对应的 多级菜单项
      this.req_products();
      // 获取图片
      // this.req_category_img();
      // if (this.IMGlist.length != 3) {
      //   setTimeout(() => {
      //     this.loading = false;
      //   }, 3500);
      // }
    },
    // ! 个数选择
    handleSelectionExceeded() {
      this.$message({
        showClose: true,
        message: this.$t(`message.Message.Upper`),
        type: 'warning',
        customClass: 'change_bar',
      });
    },
    // 多选菜单改变
    Cvalue(e) {
      // if (e.length > 10) {
      //   this.$message({
      //     showClose: true,
      //     message: this.$t(`message.Message.Upper`),
      //     type: 'warning',
      //     customClass: 'change_bar',
      //   });
      //   this.$nextTick(() => {
      //     this.$refs.cascaderAddr.checkedValue = this.values;
      //     this.$ref.cascaderAddr.computePresentContent();
      //   });
      //   return;
      // }
      this.values = e;
      console.log(e);
      this.idlist = [];
      this.labellist = [];
      e.forEach((itemo) => {
        this.idlist.push(this.options[itemo[0]].children[itemo[1] - 1].productid);
        this.labellist.push(this.options[itemo[0]].children[itemo[1] - 1].label);
      });
      this.labellist.forEach((item, index) => {
        // this.labelcolorobj.item

        this.labelcolorobj[item] = this.bgcolor[index];
      });
      console.log(this.labelcolorobj);
      console.log(this.idlist); //获取到的产品id 数组
      console.log(this.labellist); //获取到的产品label数组
    },

    // 开始预测
    ActionAnalyse() {
      // 首先 品类 和 对比产品不能为空
      if (this.name == '' || this.contrast == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.Message.selectCP`),
          type: 'warning',
          customClass: 'change_bar',
        });
      } else {
        this.loading = true;
        this.Analyse();
        this.show = 1;
      }
    },
    //清除选项
    clear_num() {
      // this.$refs['cascaderAddr'].panel.clearCheckedNodes();
      this.contrast = [];
    },
  },

  created() {
    this.req_category();
  },
  mounted() {
    console.log('产品轮廓');
    document.addEventListener('click', (e) => {
      if (this.downloadShow == true) {
        if (e.target.id != 'downloadImg') {
          this.downloadShow = false;
        }
      }
    });
    if (this.$store.state.lang == 'cn') {
      console.log('我是cn');
      this.IMGlist = ['https://newstore.vynior.com/line_cn.png', 'https://newstore.vynior.com/none_cn.png'];
    } else if (this.$store.state.lang == 'en') {
      console.log('我是en');
      this.IMGlist = ['https://newstore.vynior.com/line_en.png', 'https://newstore.vynior.com/none_en.png'];
    }
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Acategory/RoughSketch.less');
</style>
